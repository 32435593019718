import logo from './logo.svg';
import './App.css';
import * as signalR from "@microsoft/signalr";



async function getAccessToken(username, password) {
  const response = await fetch('https://test-server.balancebet.com/api/authentication/accessToken', {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({
          login: username,
          password: password,
      }),
  });

  if (response.ok) {
      const data = await response.json();
      console.log(data);
      return data.accessToken;  // Adjust according to the actual token field
  } else {
      throw new Error('Failed to authenticate');
  }
}

const connection = new signalR.HubConnectionBuilder()
    .withUrl("https://test-server.balancebet.com/api/commsHub", { accessTokenFactory: async () => await getAccessToken("hovhannes", "1234567") })
    .configureLogging(signalR.LogLevel.Information)
    .build();

async function start() {
    try {
        await connection.start();
        console.log("SignalR Connected.");
    } catch (err) {
        console.log(err);
        // setTimeout(start, 5000);
    }
};

connection.onclose(async () => {
    // await start();
});

// Start the connection.
start();

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
